import {AngularEditorConfig} from '@kolkov/angular-editor';

export const EDITOR_CONFIG: any = {
  editable: true,
  spellcheck: true,
  height: '240px',
  minHeight: '5rem',
  placeholder: 'Nhập nội dung vào đây...',
  translate: 'yes',
  uploadUrl: 'v1/images', // if needed
  customClasses: [ // optional
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ]
};

export const EDITOR_MIN_CONFIG: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  enableToolbar: true,
  showToolbar: false,
  height: '180px',
  minHeight: '5rem',
  placeholder: 'Nhập nội dung vào đây...',
  translate: 'yes',
  uploadUrl: 'v1/images', // if needed
  customClasses: [ // optional
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ]
};
